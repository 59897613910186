html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body * {
  margin: 0;
  box-sizing: border-box;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  background-image: url(../img/synth2.webp);
  background-size: cover;
  background-position: 50% 5%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: #fff;
  height: 100%;
  position: relative;
}

header {
  width: 100%;
  padding-bottom: 1em;
}

.burger {
  display: block;
  background-color: transparent;
  margin: 2rem;
  width: 35px;
  height: 30px;
  cursor: pointer;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  border: none;
}
@media (min-width: 780px) {
  .burger {
    display: none;
  }
}

.burger span {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 15px;
  transition: background-color 0.5s ease-in-out;
}

.burger span::before,
.burger span::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 15px;
  background-color: #fff;
  transition: transform 0.5s ease-in-out;
}

.burger span::before {
  transform: translateY(-10px);
}

.burger span::after {
  transform: translateY(10px);
}

.burger.active span {
  background-color: transparent;
}

.burger.active span::before {
  transform: translateY(0) rotateZ(45deg);
}

.burger.active span::after {
  transform: translateY(0) rotateZ(-45deg);
}

.mobile-navbar-wrapper {
  width: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
}
@media (min-width: 400px) {
  .mobile-navbar-wrapper {
    width: 45%;
  }
}
@media (min-width: 780px) {
  .mobile-navbar-wrapper {
    display: none;
  }
}

.mobile-navbar-wrapper.hidden {
  display: none;
}

@media (max-width: 780px) {
  .mobile-navbar-wrapper.active {
    display: flex;
  }
}

.mobile-navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  padding: 1em 0;
  /*
  .lang-btn-wrapper {
      margin-top: 1rem;

      li {
          padding: 0;
          margin: 1rem;
      }
  }

  button {
      background: transparent;
      border: 2px solid transparent;
      font-family: 'neon-neon';
      font-size: 1em;
      padding: .6rem;
      color: $neon-font-color;
      border-radius: 5px;
      text-shadow: 0 0 0.6rem #83FCEA, 0 0 1.5rem #44DDC6,
          -0.2rem 0.1rem 1rem #44DDC6, 0.2rem 0.1rem 1rem #44DDC6,
          0 -0.5rem 2rem #12B59D, 0 0.5rem 3rem #12B59D;
      transition: all .4s;

      &:hover {
          filter: brightness(1.3);
      }
  }
  */
}
@media (min-width: 780px) {
  .mobile-navbar {
    display: none;
  }
}
.mobile-navbar li {
  padding: 1.5rem;
}
.mobile-navbar a {
  margin: 1rem;
  padding: 1rem 1.5rem;
}

.desktop-navbar {
  display: none;
}
@media (min-width: 780px) {
  .desktop-navbar {
    position: relative;
    z-index: 4;
    display: flex;
    font-size: 2rem;
    padding: 1em;
    margin: 1rem;
  }
}

.desktop-navbar a {
  margin-left: 1rem;
}

.neon-button {
  border: 2px solid transparent;
  font-family: "neon-neon";
  font-size: 1em;
  text-decoration: none;
  padding: 0.2em 0.4em;
  color: #ffe6ff;
  border-radius: 5px;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  transition: all 0.3s ease-in-out;
}
.neon-button:hover, .neon-button.active {
  border: 2px solid #fff;
  border-radius: 15px;
  box-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, inset 0 0 0.6rem #ffe6ff, inset 0 0 1.5rem #ff2483, inset -0.2rem 0.1rem 1rem #ff65bd;
  transition: all 0.5s ease-in-out;
  filter: brightness(1.1);
}

.w-full {
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
  align-items: center;
}

.space-around {
  justify-content: space-around;
  align-items: center;
}

.title-wrapper {
  padding-top: 1em;
  padding-left: 1em;
  letter-spacing: 0.5rem;
  font-size: 2rem;
  text-shadow: 0 0 0.6rem #83FCEA, 0 0 1.5rem #44DDC6, -0.2rem 0.1rem 1rem #44DDC6, 0.2rem 0.1rem 1rem #44DDC6, 0 -0.5rem 2rem #12B59D, 0 0.5rem 3rem #12B59D;
}

.title-wrapper a {
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 1.6em;
  font-family: "neon-neon";
  transition: all 0.5s ease-in-out;
}
.title-wrapper a:hover {
  filter: brightness(1.3);
}
@media (max-width: 360px) {
  .title-wrapper a {
    width: 50%;
  }
}

.title-wrapper p {
  font-family: "Indie";
}

/* TRANSLATION BTN */
/* HOME */
.home {
  height: 80vh;
}

.browser-window {
  background-color: #000;
  width: 100%;
  max-width: 350px;
  height: 280px;
  border-radius: 5px;
}

.top-bar {
  border-radius: 5px 5px 0 0;
  background: linear-gradient(to top, rgb(170, 169, 169) 0%, rgb(235, 232, 232) 100%);
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
}

.circle {
  margin: 0 0.4rem;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.circle-red {
  background-color: #F1694B;
  border: 1px solid #f06142;
}

.circle-yellow {
  background-color: #D1B13E;
  border: 1px solid #c6a52f;
}

.circle-green {
  background-color: #49AE4C;
  border: 1px solid #419c44;
}

.browser-window .content {
  padding: 2rem;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.browser-window .content span {
  font-family: "Menlo";
}

.typing-effect {
  font-size: 2rem;
  position: relative;
}

@keyframes cursor-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cursor {
  display: inline-block;
  position: relative;
  top: 0;
  transform: translateY(20%);
  width: 0.4em;
  height: 1em;
  margin-left: 0.2rem;
  background-color: #fff;
  opacity: 1;
  animation: cursor-blink 0.9s infinite;
}

/* Terminal btns */
.terminal-btns {
  padding: 1rem;
}
.terminal-btns button {
  margin: 0 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: #000;
  border: thin solid #fff;
  box-shadow: 0 4px 0 0;
  color: #fff;
  font-family: "Menlo";
}
.terminal-btns button:active {
  position: relative;
  top: 2px;
}
.terminal-btns .disable {
  background-color: #363636;
}
.terminal-btns .disable:active {
  top: 0;
}

@keyframes glowingEffect {
  0% {
    box-shadow: 0 0 8px #83FCEA, 0 0 10px #44DDC6, 0 0 16px #44DDC6, 0 0 24px #44DDC6, 0 0 30px #12B59D, 0 0 35px #12B59D, 0 0 38px #83FCEA, 0 0 40px #44DDC6;
  }
  50% {
    box-shadow: 0 0 14px #83FCEA, 0 0 16px #44DDC6, 0 0 20px #44DDC6, 0 0 26px #44DDC6, 0 0 32px #12B59D, 0 0 35px #12B59D, 0 0 38px #83FCEA, 0 0 45px #44DDC6;
  }
  100% {
    box-shadow: 0 0 8px #83FCEA, 0 0 10px #44DDC6, 0 0 16px #44DDC6, 0 0 24px #44DDC6, 0 0 30px #12B59D, 0 0 35px #12B59D, 0 0 38px #83FCEA, 0 0 40px #44DDC6;
  }
}
footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 780px) {
  footer {
    padding: 1em;
    position: absolute;
    z-index: 1;
    top: 100px;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    width: 10%;
    padding: 1em;
  }
}

.social-medias-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.social-medias-wrapper::before,
.social-medias-wrapper::after {
  display: none;
}
@media (min-width: 780px) {
  .social-medias-wrapper::before,
  .social-medias-wrapper::after {
    display: block;
    content: "";
    width: 5px;
    height: 28%;
    background-color: #fff;
    border-radius: 15px;
    animation: 15s linear 1s infinite alternate glowingEffect;
    flex-grow: 1;
    box-shadow: 0 0 0.6rem #83FCEA, 0 0 1.5rem #44DDC6, -0.2rem 0.1rem 1rem #44DDC6, 0.2rem 0.1rem 1rem #44DDC6, 0 -0.5rem 2rem #12B59D, 0 0.5rem 3rem #12B59D;
  }
}

@media (prefers-reduced-motion) {
  .social-medias-wrapper::before,
  .social-medias-wrapper::after {
    animation: none;
    box-shadow: 0 0 0.6rem #83FCEA, 0 0 1.5rem #44DDC6, -0.2rem 0.1rem 1rem #44DDC6, 0.2rem 0.1rem 1rem #44DDC6, 0 -0.5rem 2rem #12B59D, 0 0.5rem 3rem #12B59D;
  }
}
.social-medias-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem;
}
@media (min-width: 780px) {
  .social-medias-content {
    flex-direction: column;
  }
}

.social-medias-content > a {
  text-decoration: none;
  color: #fff;
  font-size: 2.4rem;
  margin: 1rem;
  padding: 0.5rem;
}
.social-medias-content > a:hover {
  filter: brightness(1.8);
}

.neon-blue {
  text-shadow: 0 0 4px #83FCEA, 0 0 5px #44DDC6, 0 0 8px #44DDC6, 0 0 12px #44DDC6, 0 0 15px #12B59D, 0 0 20px #12B59D, 0 0 25px #83FCEA, 0 0 30px #44DDC6;
}

/* MAIN */
main {
  max-width: 1440px;
  margin: auto;
  padding: 1em;
}

h2 {
  text-align: center;
  font-family: "neon-neon";
  font-size: 4rem;
  padding: 0.2em 0.4em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
}

/* PROJECTS SECTION */
.intro p {
  text-align: center;
}

.projects-wrapper {
  width: 100%;
}
@media (min-width: 580px) {
  .projects-wrapper {
    width: 85%;
  }
}

.project, .about-card {
  width: 100%;
  max-width: 1000px;
  margin: 3em 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  text-align: center;
}
@media (min-width: 780px) {
  .project, .about-card {
    width: 80%;
  }
}

.project-content {
  padding: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.project-content .media {
  width: 100%;
  min-width: 200px;
  height: 100%;
  aspect-ratio: auto;
}
@media (min-width: 780px) {
  .project-content .media {
    max-width: 700px;
  }
}

.project-content video, .project-content img {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  object-fit: cover;
  aspect-ratio: auto;
}

.project-content .description {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}
.project-content .description p {
  font-size: 1.3rem;
  margin-top: 1rem;
}
@media (min-width: 780px) {
  .project-content .description p {
    font-size: 1.5rem;
  }
}
.project-content .description .tech_btn_wrapper {
  padding: 0.5rem;
}
.project-content .description .tech_btn {
  display: inline-block;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: #000;
  border: thin solid #fff;
  box-shadow: 0 4px 0 0;
  color: #fff;
  font-family: "Menlo";
  font-size: 1.2rem;
  margin: 0.6rem 0.3rem;
}
.project-content .description .tech_btn:active {
  position: relative;
  top: 2px;
}

.redirect-btn-wrapper {
  padding: 1em 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 400px) {
  .redirect-btn-wrapper {
    flex-direction: row;
  }
  .redirect-btn-wrapper a {
    margin: 0 2rem;
  }
}

.redirect-btn-wrapper a {
  text-decoration: none;
  font-size: 1em;
  color: #ffe6ff;
  padding: 1rem;
}

.redirect-btn-wrapper .fa-github {
  font-size: 1.5em;
  transition: all 0.3s ease-in-out;
}
.redirect-btn-wrapper .fa-github:hover {
  filter: brightness(1.8);
}

.redirect-btn-wrapper .demo_link {
  padding: 0.4em 0.5em 1em;
  position: relative;
  text-transform: uppercase;
  font-family: "Indie";
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  transition: all 0.3s ease-in-out;
}
.redirect-btn-wrapper .demo_link:hover {
  text-shadow: 0 0 0.8rem #ffe6ff, 0 0 1.8rem #ff65bd, -0.2rem 0.1rem 1.5rem #ff65bd, 0.2rem 0.1rem 1.5rem #ff65bd, 0 -0.5rem 2.5rem #ff2483, 0 0.5rem 3.5rem #ff2483;
  filter: brightness(1.1);
}

.redirect-btn-wrapper .demo_link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 10px;
  bottom: 8px;
  left: 0;
  box-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  transition: all 0.4s ease-in-out;
}
.redirect-btn-wrapper .demo_link::before:hover {
  box-shadow: 0 0 0.8rem #ffe6ff, 0 0 1.8rem #ff65bd, -0.2rem 0.1rem 1.5rem #ff65bd, 0.2rem 0.1rem 1.5rem #ff65bd, 0 -0.5rem 2.5rem #ff2483, 0 0.5rem 3.5rem #ff2483;
  filter: brightness(1.2);
}

/* CONTACT PAGE */
.contact-form-wrapper {
  width: 90%;
  padding: 1em;
}
@media (min-width: 580px) {
  .contact-form-wrapper {
    width: 60%;
  }
}
@media (min-width: 1100px) {
  .contact-form-wrapper {
    width: 50%;
    max-width: 780px;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

label, input, textarea {
  display: block;
  width: 100%;
}

label {
  margin: 2rem 0;
}

input, textarea {
  background: transparent;
  font-family: "SourceCode";
  font-size: 1.8rem;
  margin: 1rem;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, inset 0 0 0.6rem #ffe6ff, inset 0 0 1.5rem #ff2483, inset -0.2rem 0.1rem 1rem #ff65bd;
  color: #fff;
  padding: 0.8em;
  /* outline-color: #ff2483; */
  outline-color: #fff;
}

textarea {
  min-height: 100px;
  max-height: 700px;
  resize: vertical;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.btn-wrapper {
  width: 100%;
  padding: 1em;
}

.main-neon-btn {
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 10px;
  padding: 0.5em;
  font-family: "neon-neon";
  font-size: 2.5rem;
  letter-spacing: 0.5rem;
  color: #fff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  box-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd, inset 0 0 0.6rem #ffe6ff, inset 0 0 1.5rem #ff2483, inset -0.2rem 0.1rem 1rem #ff65bd;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.main-neon-btn:hover {
  border-radius: 15px;
  box-shadow: 0 0 1rem #ffe6ff, 0 0 2rem #ff65bd, -0.2rem 0.1rem 2rem #ff65bd, 0.2rem 0.1rem 2rem #ff65bd, inset 0 0 1.4rem #ffe6ff, inset 0 0 2rem #ff2483, inset -0.2rem 0.1rem 2rem #ff65bd;
}

/* ABOUT */
.about-page > div {
  margin: 3rem auto;
}

.bio-section-wrapper {
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 780px) {
  .bio-section-wrapper {
    display: block;
  }
}

.img-card {
  max-width: 220px;
  border-radius: 0.5rem;
  aspect-ratio: 1/1;
}
@media (min-width: 780px) {
  .img-card {
    float: left;
    margin: 2rem 2rem 0 2rem;
  }
}

.img-card img {
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 0.5rem;
}

.bio-text {
  width: 100%;
  text-align: center;
}
@media (min-width: 780px) {
  .bio-text {
    text-align: left;
    padding: 1em;
  }
}

.bio-text > p {
  margin: 2rem 0;
}

.curriculum-wrapper {
  text-align: center;
}

.curriculum-dl-btn-wrapper {
  padding: 2em;
  display: flex;
  place-content: center;
}

.curriculum-dl-btn-wrapper .main-neon-btn {
  text-decoration: none;
  font-family: "Indie";
  text-transform: uppercase;
  font-size: 1.5rem;
}

.skills-section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;
  text-align: center;
}

.tech-wrapper {
  width: 100%;
  margin: 2rem auto;
  display: flex;
  place-content: center;
  flex-wrap: wrap;
}
@media (min-width: 780px) {
  .tech-wrapper {
    width: 60%;
  }
}

.tech-wrapper img {
  width: 70px;
  max-width: 70px;
  max-height: 70px;
  margin: 1rem;
}

.rounded_logo {
  border-radius: 50%;
}

strong, em {
  font-style: normal;
  font-weight: 400;
}

.contact-form-section > p {
  text-align: center;
}

.validation-message-wrapper {
  height: 200px;
}

.validation-message {
  font-family: "Menlo";
  background-color: #0AB598;
  border: 1px solid #fff;
  padding: 2rem;
  margin: 2rem 0;
}

.validation-message i {
  margin-left: 1rem;
}

.error-message {
  font-family: "Menlo";
  background-color: #B50A0A;
  border: 1px solid #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.honey-input {
  display: none;
}

