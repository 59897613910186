@font-face {
    font-family: 'Indie';
    src: url('../fonts/neon-webfont.woff2') format('woff2'),
         url('../fonts/neon-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neon-neon';
    src: url('../fonts/neoneon-webfont.woff2') format('woff2'),
         url('../fonts/neoneon-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceCode';
    src: url('../fonts/sourcecodepro.woff') format('woff'),
         url('../fonts/sourcecodepro.woff2') format('woff2');
}


@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/montserrat-medium-webfont.woff') format('woff'),
    url('../fonts/montserrat-medium-webfont.woff') format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/montserrat-regular-webfont.woff') format('woff'),
    url('../fonts/montserrat-regular-webfont.woff') format('woff2');
}


@font-face {
    font-family: 'Stoicheion';
    src: url('../fonts/stoicheion-webfont.woff2') format('woff2'),
         url('../fonts/stoicheion-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Menlo';
    src: url('../fonts/menlo-regular-webfont.woff2') format('woff2'),
         url('../fonts/menlo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
